import styled from 'styled-components';

export const Container = styled.div`
  ${({ responsiveStyles }) => responsiveStyles}
`;

export const AccordionItem = styled.div`
  ${({ responsiveStyles }) => responsiveStyles}
  overflow: hidden;
  &.active {
    overflow: unset;
  }
`;

export const AccordionHeader = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;
  cursor: pointer;
  z-index: 1;

  & + div {
    margin-top: unset;
  }

  &.active + div {
    margin-top: -10px;
  }

  & .accordion-tick {
    &:before {
      content: ' ';
      display: block;
      width: 16px;
      height: 16px;
      background-color: transparent;
      border-radius: 100%;
      transition: all 800ms;
      margin: auto;
    }
  }
  &.active .accordion-tick {
    &:before {
      background-color: #001c72;
    }
  }
`;

export const AccordionHeaderChild = styled.div`
  ${({ showTick }) => (showTick ? `width: calc(100% - 40px);` : `width: 100%;`)}
`;

export const AccordionTick = styled.div`
  width: 28px;
  height: 28px;
  border: 2px solid #001c72;
  border-radius: 100%;
  display: flex;
  margin: auto auto auto 0;
  padding: 4px;

  &:before {
    content: ' ';
    display: block;
    width: 16px;
    height: 16px;
    background-color: transparent;
    border-radius: 100%;
    transition: all 800ms;
  }

  ${({ active }) =>
    active
      ? `
    &:before {
      background-color: #001C72;
    }
  `
      : ``}
`;

export const AccordionBody = styled.div`
  position: relative;
  transition: height 500ms;
  &.enter-active {
    overflow: hidden;
  }
`;
