import React, {
  useRef,
  createRef,
  useContext,
  useEffect,
  useState,
  useCallback
} from 'react';
import { BuilderBlockComponent } from '@builder.io/react';
import * as S from './CategorySelectorV2.styles';
import { responsiveStyles } from '../../utils/builder';
import FunnelContext from '../../context/FunnelContext';
import { CSSTransition } from 'react-transition-group';
import useLocalStorage from '../../hooks/useLocalStorage';
import useScreenResize from '../../hooks/useScreenResize';

const CategorySelectorV2 = props => {
  const { children, items } = props;
  const {
    currentTubIndex,
    currentCategory,
    setCurrentCategory,
    setCurrentProduct,
    extraObjects,
    setExtraObjects
  } = useContext(FunnelContext);
  const refs = useRef(items.map(_ => createRef()));
  const [init, setInit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setCurrentCategoryLocal] = useLocalStorage('currentCategory');
  const updateCategory = useCallback(
    category => {
      setCurrentCategory(category);
      setCurrentCategoryLocal(category);
      setExtraObjects(
        Object.assign(extraObjects, {
          currentCategory: category
        })
      );
    },
    [setCurrentCategory, setCurrentCategoryLocal, setExtraObjects, extraObjects]
  );

  const runItems = useCallback(
    (reset = false) => {
      items.forEach((item, index) => {
        const ref = refs.current[index];
        if (reset) {
          ref.current.style.height = `unset`;
        }
        if (item?.category === currentCategory) {
          setTimeout(() => {
            ref.current.style.height = `${ref.current.offsetHeight}px`;
          }, 1000);
        } else {
          ref.current.style.height = `0px`;
        }
      });
    },
    [items, currentCategory]
  );

  useEffect(() => {
    if (!init) {
      runItems();
      updateCategory(currentCategory);
      setInit(true);
    }
  }, [currentCategory, items, runItems, updateCategory, init, setInit]);

  useScreenResize(() => runItems(true));

  const duration = 500;
  const onEnter = node => {
    node.style.height = `${node?.childNodes[0].offsetHeight}px`;
    setLoading(true);
  };
  const onEntering = node => {
    node.style.height = `${node?.childNodes[0].offsetHeight}px`;
  };
  const onEntered = node => {
    node.style.height = `${node?.childNodes[0].offsetHeight}px`;
    if (loading) setLoading(false);
  };
  const onExit = node => {
    node.style.height = `0px`;
  };
  const onExiting = node => {
    node.style.height = `0px`;
  };
  const onExited = node => {
    node.style.height = `0px`;
  };

  const handleClick = item => {
    if (loading) return;
    if (item.category === currentCategory) return;
    updateCategory(item.category);
    const productPrefix =
      item.category === `onetime` ? `onetime` : `subscription`;
    setCurrentProduct(`${productPrefix}_${currentTubIndex}`);
  };

  return (
    <S.Container
      responsiveStyles={responsiveStyles(props.builderBlock?.responsiveStyles)}
    >
      {items?.map((item, index) => {
        const { category, showTick = false } = item;
        const isActive = category === currentCategory;
        return (
          <S.AccordionItem
            key={index}
            className={`accordion-item ${isActive ? `active` : ``}`}
            responsiveStyles={responsiveStyles(
              children[index].props.block?.responsiveStyles
            )}
          >
            <S.AccordionHeader
              className={`accordion-header ${isActive ? `active` : ``}`}
              onClick={() => handleClick(item)}
            >
              {showTick ? <S.AccordionTick active={isActive} /> : null}
              <S.AccordionHeaderChild showTick={showTick}>
                <BuilderBlockComponent
                  block={children[index].props.block.children[0]}
                />
              </S.AccordionHeaderChild>
            </S.AccordionHeader>

            <CSSTransition
              in={isActive}
              timeout={duration}
              onEnter={onEnter}
              onEntering={onEntering}
              onEntered={onEntered}
              onExit={onExit}
              onExiting={onExiting}
              onExited={onExited}
            >
              <S.AccordionBody
                className="accordion-body"
                ref={refs.current[index]}
              >
                <BuilderBlockComponent
                  block={children[index].props.block.children[1]}
                />
              </S.AccordionBody>
            </CSSTransition>
          </S.AccordionItem>
        );
      })}
    </S.Container>
  );
};

export default CategorySelectorV2;
